import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getSchoolNoticesRequest} from 'BackendRenderedComponents/dashboard/redux/actions';
import {selectSchoolNotices} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import url from 'Services/url/Url';

import {NotificationPanel, NotificationEmptyState} from '../NotificationPanel';

import {MegaphoneIcon} from './MegaphoneIcon';

export class UnconnectedSchoolNotices extends Component {
    componentDidMount() {
        this.props.actions.getSchoolNoticesRequest();
    }

    render() {
        const {
            schoolNotices: {total, items, isLoading},
            redirectUrl,
        } = this.props;

        return (
            <NotificationPanel
                title="School Notices"
                total={total}
                items={items}
                redirectUrl={redirectUrl}
                isLoading={isLoading}
                emptyState={
                    <NotificationEmptyState
                        title={'All quiet!'}
                        description={
                            <span>
                                There are no school notices today. Find out how
                                notices work{' '}
                                <KeyboardFocusableLink
                                    url={url.ARBOR.MY_HOMEPAGE_SUPPORT}
                                    target="_blank"
                                >
                                    here
                                </KeyboardFocusableLink>
                                .
                            </span>
                        }
                        icon={<MegaphoneIcon />}
                    />
                }
            />
        );
    }
}

UnconnectedSchoolNotices.propTypes = {
    schoolNotices: PropTypes.exact({
        isLoading: PropTypes.bool,
        total: PropTypes.number,
        items: PropTypes.arrayOf(
            PropTypes.exact({
                id: PropTypes.string,
                title: PropTypes.string,
                startDate: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
    }),
    redirectUrl: PropTypes.string,
    actions: PropTypes.exact({
        getSchoolNoticesRequest: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    schoolNotices: selectSchoolNotices(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getSchoolNoticesRequest,
        },
        dispatch,
    ),
});

export const SchoolNotices = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedSchoolNotices);
