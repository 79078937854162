/* eslint-disable camelcase */
import {httpGet} from 'Interfaces/httpClient';
import urlConfig from 'Services/url/Url';

import {INotificationMetadata} from '../userNotifications/UserNotification';

export interface ILoggedOutUserSettings {
    logged_in?: false;
    login_form_enabled: boolean;
    login_form_message: string;
    session_id?: string;
}

export type UserFavouritePage = {
    userFavouritePageId: number;
    route: string;
    customName: string;
};

export interface ILoggedInUserSettings {
    logged_in?: true;
    applicationId?: string;
    display_name?: string;
    gainsight?: {
        enabled?: boolean;
        key?: string;
    };
    helpCentreTooltip?: string;
    helpCentreUrl?: string;
    isLiveFeedsEnabled?: boolean;
    loginIssuePage?: string | null;
    notifications?: INotificationMetadata;
    organizationName?: string;
    sendAlertTime?: number;
    session_id?: string;
    showAlertButton?: boolean;
    showArborButton?: boolean;
    showCommunityButton?: boolean;
    showTrainingHubButton?: boolean;
    showSearch?: boolean;
    calendarUrl?: string | null;
    userId?: number;
    user_type?: string;
    userFavouritePages?: UserFavouritePage[];
    payment?: {
        enabledPayment?: boolean;
        enableMobilePay?: boolean;
        stripePublishableKey?: string | null;
        stripeApiVersion?: string | null;
        isTestPaymentEnv?: boolean;
    };
}

export function isLoggedInUserSettings(
    settings: ILoggedInUserSettings | ILoggedOutUserSettings,
): settings is ILoggedInUserSettings {
    return settings.logged_in === true;
}

export interface IUserSettingsResponse {
    items: [ILoggedOutUserSettings | ILoggedInUserSettings];
    action_params?: {
        message?: string;
    };
}

export function fetchCurrentUserSettings(sourceTag = 'unknown') {
    if (sourceTag === 'unknown') {
        console.warn('fetchCurrentUserSettings expects a source for analytics');
    }

    return httpGet(urlConfig.AUTH.CURRENT_USER_SETTINGS, {
        from: sourceTag,
    }) as Promise<IUserSettingsResponse>;
}
