import classNames from 'classnames';
import PropTypes from 'prop-types';

import {Icon} from 'Components/icon';

import './messageContainer.scss';

export function MessageContainer(props) {
    const {caption, rootStyle, rootClass} = props;

    return (
        <div className="message-container">
            {caption && (
                <div
                    style={rootStyle}
                    className={classNames('message-container-root', {
                        [rootClass]: rootClass,
                    })}
                >
                    <div className="message-fluid-container">
                        <Icon iconName="error" />
                        <span className="message-container-caption">
                            {caption}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

MessageContainer.propTypes = {
    caption: PropTypes.string.isRequired,
    rootStyle: PropTypes.object,
    rootClass: PropTypes.string,
};
