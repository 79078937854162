import {Editor} from 'tinymce';

import {SISMergeFieldConfig} from '../types';

import {addSisMergeFieldSyntax} from './addSisMergeFieldSyntax';

import {transformSisMergeFieldConfig} from '.';

export const generateMergeFieldAutoCompleterConfig = (
    editor: Editor,
    mergeFieldConfig?: SISMergeFieldConfig,
) => {
    if (mergeFieldConfig) {
        const mergeFields = transformSisMergeFieldConfig(mergeFieldConfig);

        editor.ui.registry.addAutocompleter('Merge Fields', {
            trigger: '{{',
            fetch: (pattern) => {
                const validFields = mergeFields.filter(
                    (mergeField) =>
                        mergeField.label.toLowerCase().indexOf(pattern) !==
                            -1 ||
                        mergeField.value.toLowerCase().indexOf(pattern) !== -1,
                );

                const result = validFields.map((field) => ({
                    value: field.value,
                    text: field.label,
                }));

                return Promise.resolve(result);
            },

            onAction: (autocompleteApi, rng, value) => {
                const targetField = mergeFields.find(
                    (field) => value === field.value,
                );
                if (targetField) {
                    editor.selection.setRng(rng);
                    editor.insertContent(
                        addSisMergeFieldSyntax(value, targetField.label),
                    );
                    autocompleteApi.hide();
                }
            },
            minChars: 0,
        });
    }
};
