import {useEffect, useRef, useState} from 'react';

import {FocusTrappingModalContent} from 'Components/focusTrappingModal';
import {SideBar} from 'Components/sideBar';
import {WINDOW_MANAGER} from 'Config/Events';
import {
    APP_CONTAINER_ID,
    WINDOW_MANAGER_EXT_CONTENT_PLACEHOLDER_ID,
} from 'Constants/idStrings';
import {renderToAppRoot} from 'Interfaces/renderTargets';
import {renderWithStore} from 'Renderer/Store';
import {usePubSub} from 'Services/pubsub';

import {WindowData} from './types';

import './windowManager.scss';

export const ReactWindowManager = () => {
    const [windows, setWindows] = useState<WindowData[]>([]);

    // value for windows was stale in the event handlers below, so
    // we're sticking it on a ref
    const windowsRef = useRef<WindowData[]>(windows);

    useEffect(() => {
        windowsRef.current = windows;
    }, [windows]);

    const refocusElementThatTriggeredWindow = (windowData: WindowData) => {
        const rendererParams = windowData?.rendererParams;
        const elementToRefocus = rendererParams?.elementToRefocus;
        const refocusCallback = rendererParams?.refocusCallback;
        if (refocusCallback && typeof refocusCallback === 'function') {
            refocusCallback();
        } else if (
            elementToRefocus &&
            typeof elementToRefocus.focus === 'function'
        ) {
            elementToRefocus.focus();
        }
    };
    const handleAdd = (windowContent: WindowData) => {
        setWindows([...windowsRef.current, windowContent]);
    };
    const handleRemove = () => {
        refocusElementThatTriggeredWindow(
            windowsRef.current?.[windowsRef.current.length - 1],
        );

        if (
            document.getElementById(APP_CONTAINER_ID)?.childElementCount === 0
        ) {
            // This is to handle an edge case of logging in when there are certain login issues,
            // for example when logging in as a parent for the first time, and needing to verify
            // a student/guardian relationship. The backend shows the window for this via a 302
            // redirect. When this is loaded the window is present, but nothing has been rendered into
            // the main app container. This also means the sidebar and logout button are not rendered.
            // In order to allow the user to logout if they need to, render the SignOutButton into the app root.
            renderToAppRoot(renderWithStore(<SideBar />));
        }

        setWindows([...windowsRef.current].slice(0, -1));
    };
    const handleRemoveAll = () => {
        // We want to refocus the element that triggered the first window in the stack, as
        // the focusElements from window higher in the stack will be within the stack
        refocusElementThatTriggeredWindow(windowsRef.current?.[0]);

        setWindows([]);
    };
    const handleRemoveAllAndAdd = (windowContent: WindowData) => {
        // to avoid async weirdness with setting the window stack to empty and then pushing
        // a fresh one, we instead just reset the stack in one go
        setWindows([windowContent]);
    };

    usePubSub(WINDOW_MANAGER.ADD, handleAdd);
    usePubSub(WINDOW_MANAGER.REMOVE, handleRemove);
    usePubSub(WINDOW_MANAGER.REMOVE_ALL, handleRemoveAll);
    usePubSub(WINDOW_MANAGER.REMOVE_ALL_AND_ADD, handleRemoveAllAndAdd);

    return (
        <div
            className="window-manager"
            role="presentation"
            data-testid="window-manager"
        >
            {windows.map((windowItem) => (
                <FocusTrappingModalContent
                    key={windowItem.id}
                    onClose={() => handleRemove()}
                    className="window-manager__window-container"
                    ariaLabel={`Dialog: ${windowItem.title}`}
                    removeChildrenPadding={true}
                >
                    <h2 className="window-manager__window-header">
                        {windowItem.title}
                    </h2>
                    <section
                        aria-label="Popup content"
                        className="window-manager__window-content"
                    >
                        {windowItem.content}
                    </section>
                    {windowItem.footerItems && (
                        <section
                            aria-label="Popup Footer"
                            className="window-manager__window-footer"
                        >
                            {windowItem.footerItems}
                        </section>
                    )}
                </FocusTrappingModalContent>
            ))}
        </div>
    );
};
export const ExtWindowManager = () => {
    // showExtOverlay controls the hiding/showing of an overlay div which will
    // be placed behind the Ext content in WINDOW_MANAGER_EXT_CONTENT_PLACEHOLDER_ID.
    // See hideWindowOverlay in client/Mis/window/Window.js
    const [showExtOverlay, setShowExtOverlay] = useState(false);

    const handleShow = () => {
        setShowExtOverlay(true);
    };

    const handleHide = () => {
        setShowExtOverlay(false);
    };

    usePubSub(WINDOW_MANAGER.SHOW, handleShow);
    usePubSub(WINDOW_MANAGER.HIDE, handleHide);

    // Note the WINDOW_MANAGER_EXT_CONTENT_PLACEHOLDER_ID div is used by the
    // Mis.window.Window component to render into. This is placed after the react one
    // in the dom so that the Ext windows are in layer above. An example use case of this
    // is when an Ext confirmation modal is rendered from a react modal
    return (
        <div
            className="window-manager"
            role="presentation"
            data-testid="window-manager"
        >
            <div
                className={
                    showExtOverlay
                        ? 'window-background-overlay'
                        : 'window-background-transparent'
                }
                role="presentation"
            >
                <div
                    id={WINDOW_MANAGER_EXT_CONTENT_PLACEHOLDER_ID}
                    role="presentation"
                />
            </div>
        </div>
    );
};
