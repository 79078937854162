import {Button} from 'Components/button/base';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {LoadingSpinner} from 'Components/loadingSpinner';
import {logEventToGainsight} from 'Services/gainsight';

import {NewTabButton} from '../NewTabButton';
import {NewTabIcon} from '../NewTabIcon';
import {PageSearch} from '../PageSearch';
import {SearchFilters} from '../SearchFilters';
import {FilterableEntity} from '../SearchFilters/searchFilters';
import {filterPages} from '../utils/filterPages';
import {SearchItem} from '../utils/useSearchQuery';

import {HelpCentreItem} from './HelpCentreItem';
import {Highlighter} from './Highlighter';
import {NoResult} from './NoResult';

import './searchDropdownContents.scss';

type SearchDropdownContentsProps = {
    onViewAllClick: () => void;
    groups: SearchItem[][];
    articles: SearchItem[];
    shouldShowResults: boolean;
    searchValue: string;
    isLoading?: boolean;
    filteredPages: ReturnType<typeof filterPages>;
    filteredFavouritePages: ReturnType<typeof filterPages>;
    isArticlesLoading?: boolean;
    searchFilter: FilterableEntity;
    onSearchFilterChange: (newEntityTypeId: FilterableEntity) => void;
    onlyCurrentResults: boolean;
    onCurrentToggleChange: (onlyCurrentResults: boolean) => void;
};

export const SearchDropdownContents = ({
    groups,
    onViewAllClick,
    shouldShowResults,
    searchValue,
    filteredPages,
    filteredFavouritePages,
    isLoading,
    articles,
    isArticlesLoading,
    searchFilter,
    onSearchFilterChange,
    onlyCurrentResults,
    onCurrentToggleChange,
}: SearchDropdownContentsProps) => {
    const isPagesFound =
        filteredPages.length > 0 || filteredFavouritePages.length > 0;
    if (!isLoading && !shouldShowResults && !isPagesFound) {
        return (
            <div>
                <SearchFilters
                    entityType={searchFilter}
                    onFilterChange={onSearchFilterChange}
                    onlyCurrent={onlyCurrentResults}
                    onCurrentToggle={onCurrentToggleChange}
                />
                <div
                    className="search-dropdown__help-text"
                    data-testid="SearchDropdownContents-helpText"
                >
                    Search for students, staff, pages and more. Or, start typing
                    a question to browse
                    <br />
                    Help Centre results. Learn what you can search for in
                    this&nbsp;
                    <KeyboardFocusableLink
                        target="_blank"
                        tabIndex={-1}
                        url="https://support.arbor-education.com/hc/en-us/articles/115000214509-Using-Arbor-s-Global-search"
                    >
                        Help Centre article
                    </KeyboardFocusableLink>
                    .
                </div>
            </div>
        );
    }
    if (
        !isLoading &&
        !isArticlesLoading &&
        groups.length === 0 &&
        !isPagesFound &&
        articles.length === 0
    ) {
        return (
            <>
                <SearchFilters
                    entityType={searchFilter}
                    onFilterChange={onSearchFilterChange}
                    onlyCurrent={onlyCurrentResults}
                    onCurrentToggle={onCurrentToggleChange}
                />
                <NoResult onViewAllClick={onViewAllClick} />
            </>
        );
    }
    return (
        <>
            <div
                className="search-dropdown__items-container"
                // to solve focus issue on firefox
                tabIndex={-1}
            >
                <SearchFilters
                    entityType={searchFilter}
                    onFilterChange={onSearchFilterChange}
                    onlyCurrent={onlyCurrentResults}
                    onCurrentToggle={onCurrentToggleChange}
                />
                <PageSearch
                    searchValue={searchValue}
                    filteredPages={filteredFavouritePages}
                    title="Favourites"
                />
                <PageSearch
                    searchValue={searchValue}
                    filteredPages={filteredPages}
                    title="Pages"
                />
                {isLoading ? (
                    <div
                        className="search-dropdown__loading-spinner-container"
                        aria-label="Search results for students, staff, etc. are loading."
                    >
                        <LoadingSpinner className="search-dropdown__loading-spinner" />
                    </div>
                ) : (
                    groups.map((group, groupIndex) => (
                        <div
                            className="search-dropdown__group-container"
                            key={groupIndex}
                        >
                            <div className="search-dropdown__group-title">
                                {group[0].group}
                            </div>
                            {group.map((item, itemIndex) => (
                                <KeyboardFocusableLink
                                    url={item.clickUrl}
                                    onClick={() =>
                                        logEventToGainsight(
                                            'SearchItemVisited',
                                            {
                                                type: item.group,
                                                url: item.clickUrl,
                                            },
                                        )
                                    }
                                    insetFocusRing
                                    tabIndex={-1}
                                    key={itemIndex}
                                    linkWrapperClassName="search-dropdown__group-item__container"
                                >
                                    {item.icon && (
                                        <span className="search-dropdown__group-item__icon">
                                            <Icon iconName={item.icon} />
                                        </span>
                                    )}
                                    <span className="search-dropdown__group-item__title">
                                        <Highlighter
                                            text={item.title}
                                            highlight={searchValue.split(' ')}
                                        />
                                    </span>
                                    <span className="search-dropdown__group-item__description">
                                        {item.description}
                                    </span>
                                    {item.clickUrl && (
                                        <NewTabButton itemUrl={item.clickUrl} />
                                    )}
                                </KeyboardFocusableLink>
                            ))}
                        </div>
                    ))
                )}
                {!isLoading && isArticlesLoading && (
                    <div
                        className="search-dropdown__loading-spinner-container"
                        aria-label="Help Centre search results are loading."
                    >
                        <LoadingSpinner className="search-dropdown__loading-spinner" />
                    </div>
                )}
                {!isLoading && !isArticlesLoading && articles?.length > 0 && (
                    <div className="search-dropdown__group-container">
                        <div className="search-dropdown__group-title">
                            Help Centre Articles
                            <KeyboardFocusableButton
                                buttonWrapperClassName="search-dropdown__secondary-small__wrapper"
                                className="search-dropdown__secondary-small"
                                borderRadius="4px"
                                onClick={() => {
                                    window.open(
                                        `https://support.arbor-education.com/hc/en-us/search?utf8=%E2%9C%93&query=${encodeURI(
                                            searchValue,
                                        )}`,
                                    );
                                }}
                            >
                                Show all <NewTabIcon />
                            </KeyboardFocusableButton>
                        </div>
                        {articles.map((item, itemIndex) => (
                            <HelpCentreItem
                                item={item}
                                itemIndex={itemIndex}
                                searchValue={searchValue}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className="search-dropdown__button-container">
                <Button
                    onClick={onViewAllClick}
                    text="View all results"
                    icon="search"
                />
            </div>
        </>
    );
};
