import {ColumnTitleData, TitleAction} from 'Components/column/types';
import ExternalConfig from 'Config/External';
import {raiseDevError} from 'Interfaces/error/Error';
import {unmountAppRoot} from 'Interfaces/renderTargets';
import Scroll from 'Interfaces/Scroll';
import {
    removeAllSlideoversWithoutAnimation,
    setLatestSlideover,
} from 'Interfaces/Slideover';
import {setCurrentHelpCentreUrl} from 'Services/url/Url';
import {NavigationItem} from 'State/navigation/api';

import {focusHeaderContainer} from './focusHeaderContainer';
import {renderModels} from './Model';
import {renderPage} from './page';
import {renderPartial} from './Partial';
import {renderSlideovers} from './slideover';
import {renderStores} from './Store';
import {renderWindows} from './window';

export type FormActionValidatorsData = Record<
    string,
    | []
    | Record<
          string,
          {
              type: 'endDate' | 'required' | 'default';
              startDate?: string;
          }[]
      >
>;

export type RendererParams = {
    contentRequestUrl?: string;
    type?: string;
    elementToRefocus: HTMLElement | null;
    refocusCallback: (() => void) | null;
    role?: string | null; // This can be added in renderWindowContent
    parentFilterPanelId?: string; // This can be added in renderExtContent
    parentFormActions?: FormActionValidatorsData;
    redirectUrl?: string;
    launchedFromActionLauncher?: boolean;
};

export type BackendComponentData = {
    content?: string | BackendComponentData[];
    props?: any;
    componentName: string;
    xtype?: string;
    type?: string;
};

export type SlideoverContentItem = {
    content: string | BackendComponentData[];
    props: {
        dockedItems?: BackendComponentData[];
        panelTitle?: string;
        panelTitleBackButton: boolean;
        editUrl?: string;
        editUrlAccessible?: boolean;
        editUrlTooltip?: string;
        formActions?: FormActionValidatorsData;
        enableCustomFavourite?: boolean;
        id?: string;
    };
};

export type WindowContentItem = {
    content: string | BackendComponentData[];
    props: {
        dockedItems?: BackendComponentData[];
        title: string;
        header: {
            hidden: boolean;
        };
        role?: string | null;
        formActions?: FormActionValidatorsData;
        id?: string;
    };
    componentName: string;
    xtype?: string;
    type?: string;
};

export type SlideoverRendererData = {
    type: 'slideover';
    content: SlideoverContentItem[];
};
export type WindowRendererData = {
    type: 'window';
    content: WindowContentItem[];
    props?: any;
};

export type PageRendererData = {
    type: 'page';
    content: string | BackendComponentData[];
    props?: {
        columnTitle?: ColumnTitleData;
        backButtonUrl?: string;
        backButton?: string;
        titleAction?: TitleAction;
        formActions?: FormActionValidatorsData;
    };
    helpCentreUrl?: string;
    navigation?: {
        items: NavigationItem[];
        name: string;
        parentStaticRoute?: string;
        parentStaticTreeOptionalParams?: Record<string, string | null>;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subNav: any;
};
export type PartialPageRendererData = {
    type: 'partial-page';
    content: string | BackendComponentData[];
    props?: any;
};

export type RendererData =
    | (
          | SlideoverRendererData
          | WindowRendererData
          | PageRendererData
          | PartialPageRendererData
      ) & {
          stores?: any[];
          models?: any[];
          redirectUrl?: string;
      };

// Minimal type definition for a Mis.container.Partial component
export type ExtPartialComponent = {
    getEl: () => {dom: Element};
    destroyed: boolean;
    id: string;
};

/**
 * Main render function that is used for rendering any type of content received from backend.
 * It checks for the type of the response (page|window|slideover|partial-page) and calls the specific renderer for the type.
 *
 * THIS METHOD RECEIVES AN OBJECT WITH CONFIGURATION
 *
 * @access public
 * @since v2.2.0
 * @param {Object} config - Main configuration object that contains other paramteres as configuration.
 * @param {Object} config.data - Data to be rendered into a specific view.
 * @param {String} config.url - Url of the content to render. Used for pushing to history state on page render or adding to slideover queue.
 * @param {Boolean} config.reload=false - Reload param that is used to mark the page as reloaded, meaning that the url won't be pushed to history stack.
 * @param {Boolean} config.skipHistoryPush=false - skipHistoryPush param is used to stop the url being push to history stack.
 * @param {Object} config.partial=null - partial is used for partial-page views, representing the Ext Component to be passed down to partial rendering mechanism and should be used to display partial content.
 *
 * @memberof Renderer
 */
export function render({
    data,
    url,
    reload = false,
    skipHistoryPush = false,
    partial = null,
    elementToRefocus = null,
    refocusCallback = null,
    launchedFromActionLauncher = false,
}: {
    data: RendererData | string;
    url: string;
    reload?: boolean;
    skipHistoryPush?: boolean;
    partial?: ExtPartialComponent | null;
    elementToRefocus?: HTMLElement | null;
    refocusCallback?: (() => void) | null;
    launchedFromActionLauncher?: boolean;
}) {
    try {
        if (!data) {
            throw new Error('The response is empty!');
        }

        if (typeof data === 'string') {
            if (!ExternalConfig.getConfig().debugEnvironment) {
                throw new Error(`String data sent to Renderer! ${data}`);
            }
            if (data.startsWith('<!--')) {
                // This case happens when you have incorrect syntax in a twig file.
                // It sends a formatted message, including the error, and the twig file it's
                // trying to parse.
                // Use a `<pre>` tag to preserve formatting, and replce < with ⍃ to avoid the browser
                // trying to render the xml tags as html
                raiseDevError({
                    title: 'String data sent to renderer!',
                    message: `<pre lang="xml">${data.replace(/</g, '⍃')}</pre>`,
                });
                return;
            }
            // This case typically happens when you do a `dump($variable); die();` in the backend.
            raiseDevError({
                title: 'String data sent to renderer!',
                message: data,
            });
            return;
        }

        renderModels(data);
        renderStores(data);

        if (data.type) {
            // Parameters to be passed to all rendered components.
            const rendererParams: RendererParams & {contentRequestUrl: string} =
                {
                    contentRequestUrl: url,
                    redirectUrl: data.redirectUrl,
                    type: data.type,
                    elementToRefocus,
                    refocusCallback,
                    launchedFromActionLauncher,
                };
            switch (data.type) {
                case 'page': {
                    // Unmount the previous page so the new page can be safely rendered
                    unmountAppRoot();

                    /**
                     * Using a timeout here as there can be a 1 tick delay in form fields
                     * deregistering if they are rendered in Ext.js components.
                     * This causes an issue if you soft-reload the same page, as the old form
                     * field may not have deregistered before the new ones register
                     * Once we have no Ext.js container components wrapping any form fields,
                     * we should hopefully be able to remove this
                     **/
                    setTimeout(() => {
                        // On every page load clear all slideovers
                        removeAllSlideoversWithoutAnimation();
                        focusHeaderContainer();
                        setCurrentHelpCentreUrl(data.helpCentreUrl ?? null);
                        renderPage(data, rendererParams, skipHistoryPush);
                        if (reload) {
                            Scroll.restorePageScroll();
                        } else {
                            Scroll.scrollPageTop();
                            Scroll.savePageScroll();
                        }
                    }, 0);
                    break;
                }
                case 'window': {
                    Scroll.savePageScroll();
                    renderWindows(data, rendererParams);
                    Scroll.restorePageScroll();
                    break;
                }
                case 'slideover': {
                    Scroll.savePageScroll();
                    setLatestSlideover(url);
                    renderSlideovers(data, rendererParams);
                    Scroll.restorePageScroll();
                    break;
                }
                case 'partial-page': {
                    renderPartial(data, partial, rendererParams);
                    break;
                }
                default: {
                    throw new Error(
                        'The response type has to be valid! It should be either: page, window, slideover or partial-page!',
                    );
                }
            }
        } else {
            throw new Error(
                "The response doesn't have a specified type! It should be either: page, window, slideover or partial-page!",
            );
        }
    } catch (e) {
        Raven.captureException(e, {
            extra: {
                message: {
                    error: e,
                },
            },
        });
        raiseDevError({title: 'Response error!', message: e.message});
        console.error(e);
    }
}
