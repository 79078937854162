import {Editor as TinyMCEReactWrapper} from '@tinymce/tinymce-react';
import classNames from 'classnames';
import {useEffect, useRef} from 'react';
import * as React from 'react';
import {Editor} from 'tinymce';

import {FormFieldWrapper} from 'Components/formField';
import {
    REACT_WINDOW_MANAGER_PLACEHOLDER_ID,
    SLIDEOVER_PLACEHOLDER_ID,
} from 'Constants/idStrings';
import {RendererParams} from 'Renderer/Renderer';
import {SISFormFieldLabelProps} from 'Services/formField/FormField';

import {
    SISMergeFieldConfig,
    SisSnippetBtnConfig,
    SISWordCounterConfig,
} from './types';
import {
    generateInitObject,
    WordCounter,
    useWordCounter,
    sanitizeEditorInput,
    addTinyWrapperDiv,
    formatHTML,
} from './utils';
import {checkEditorCanBeCleared} from './utils/checkEditorCanBeCleared';
import {generateMergeFieldAutoCompleterConfig} from './utils/generateMergeFieldAutoCompleterConfig';
import {moveTinyPopupDiv} from './utils/moveTinyPopupDiv';

import './htmlEditor.scss';

let nextEditorId = 0;

export type HtmlEditorProps = {
    value?: string;
    enableAlignments?: boolean;
    enableForegroundColor?: boolean;
    enableBackgroundColor?: boolean;
    enableFont?: boolean;
    enableFontSize?: boolean;
    enableFormat?: boolean;
    enableImage?: boolean;
    enableLinks?: boolean;
    enableLists?: boolean;
    enableTables?: boolean;
    enablePageBreak?: boolean;
    emptyText?: string;
    autofocus?: string | true;
    height?: number;
    mergeFieldBtnConfig?: SISMergeFieldConfig;
    wordCounter?: SISWordCounterConfig;
    snippetBtnConfig?: SisSnippetBtnConfig;
    rendererParams?: RendererParams;
    onChange?: (val: string) => void;
    errors?: string[];
    autoFillUrl?: string;
    autoFillParams?: object;
    isLoading?: boolean;
    setRef?: React.RefObject<HTMLDivElement>;
    enablePrint?: boolean;
} & SISFormFieldLabelProps;

export const HtmlEditor = (props: HtmlEditorProps) => {
    const {
        enableFormat,
        mergeFieldBtnConfig,
        wordCounter,
        rendererParams: {type: renderingContext = ''} = {},
        errors,
    } = props;

    const thisEditorId = useRef(nextEditorId);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        nextEditorId++;
    }, []);

    const editorRef = useRef<Editor>();

    const getSubmitValue = () => {
        if (
            editorRef.current &&
            editorRef.current.getContent() &&
            editorRef.current.getContent() !== ''
        ) {
            return addTinyWrapperDiv(
                formatHTML(editorRef.current.getContent() || '').trim(),
            );
        }

        return '';
    };

    const {updateCharacterCount, setWordCount, wordCounterParams} =
        useWordCounter(wordCounter, editorRef);

    const labelProps = {
        label: props.fieldLabel,
        tooltip: props.tooltipMIS ?? props.tooltip,
        tooltipUrl: props.tooltipUrl,
        isLoading: props.isLoading,
        setRef: props.setRef,
        required: props.required,
    };

    const toggleFullScreen = (e) => {
        if (e.key === 'Escape') {
            editorRef.current?.execCommand('mceFullScreen');
        }
    };

    const onChange = () => {
        if (typeof props.onChange === 'function') {
            props.onChange(getSubmitValue());
        }
    };

    return (
        <FormFieldWrapper
            {...labelProps}
            focusCallback={() => {
                if (editorRef.current) {
                    editorRef.current.focus();
                }
            }}
        >
            <div
                className={classNames('html-editor__container', {
                    'html-editor__container-word-count': wordCounter,
                })}
            >
                <TinyMCEReactWrapper
                    tinymceScriptSrc={'/dist/tinymce/tinymce.min.js'}
                    onInit={(event, editor) => {
                        editorRef.current = editor;
                        containerRef.current =
                            editor.editorContainer as unknown as HTMLDivElement;
                        if (!enableFormat) {
                            // Tiny doesn't let you disable a shortcut, and their remove
                            // function doesn't seem to let you delete default shortcuts
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            const {shortcuts} = editor.shortcuts;
                            Object.keys(shortcuts).forEach((key) => {
                                if (
                                    [
                                        '66,meta', // bold
                                        '73,meta', // italic
                                        '85,meta', // underline
                                    ].includes(key)
                                ) {
                                    delete shortcuts[key];
                                }
                            });
                        }
                        generateMergeFieldAutoCompleterConfig(
                            editor,
                            mergeFieldBtnConfig,
                        );
                        editor.on(
                            'WordCountUpdate',
                            ({wordCount: {words, characters}}) => {
                                setWordCount(words);
                                updateCharacterCount(characters);
                                if (!editorRef.current) {
                                    return;
                                }
                                const editorContent =
                                    editorRef.current.getContent();
                                if (
                                    words === 0 &&
                                    characters === 0 &&
                                    checkEditorCanBeCleared(editorContent)
                                ) {
                                    editorRef.current.setContent('');
                                }
                            },
                        );

                        // the editor's SetContent event only fires when content is "committed"
                        // e.g. when focus leaves the editor, or when you press the enter key.
                        // The input event gets fired on every keypress, so it's better for form
                        // entry, but we also need the listener on SetContent because the input
                        // event does not get fired if content is changed programatically (e.g.
                        // via the snippet modal). Additionally, we need separate listeners for
                        // formatting events
                        editor.on('input', onChange);
                        editor.on('SetContent', onChange);
                        editor.on('FormatApply', onChange);
                        editor.on('FormatRemove', onChange);
                        editor.on('TableModified', onChange);

                        editor.on(
                            'FullscreenStateChanged',
                            (fullscreenEvent) => {
                                // fullscreenEvent.state is true when going into fullscreen mode and false when leaving fulscreen mode
                                if (fullscreenEvent.state) {
                                    editor.on('keydown', toggleFullScreen);
                                } else {
                                    editor.off('keydown', toggleFullScreen);
                                }
                            },
                        );

                        let targetContainer: Node | null = null;
                        if (renderingContext === 'slideover') {
                            targetContainer = document.getElementById(
                                SLIDEOVER_PLACEHOLDER_ID,
                            );
                        } else if (renderingContext === 'window') {
                            targetContainer = document.getElementById(
                                REACT_WINDOW_MANAGER_PLACEHOLDER_ID,
                            );
                        } else {
                            // fall back to sticking it on the body. We still call this
                            // function so that each tiny sink gets given its own unique ID
                            // to prevent a desync of editors and their placeholders
                            targetContainer = document.body;
                        }

                        if (targetContainer) {
                            moveTinyPopupDiv({
                                targetContainer,
                                editorId: thisEditorId.current,
                            });
                        }
                    }}
                    initialValue={sanitizeEditorInput(props.value)}
                    init={generateInitObject(props)}
                />
                {errors && errors.length > 0 && (
                    <ul role="presentation" className="html-editor__error-list">
                        {errors.map((error) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                )}
                {wordCounter && <WordCounter {...wordCounterParams} />}
            </div>
        </FormFieldWrapper>
    );
};
