export const MegaphoneIcon = () => (
    <svg
        width="84"
        height="92"
        viewBox="0 0 84 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M65.262 7.33829L27.6343 3.07952C26.8681 2.99755 26.0932 3.12104 25.3895 3.43722C24.6859 3.7534 24.0792 4.25081 23.6319 4.87806L0.886637 36.2684C0.427275 36.9048 0.138271 37.6472 0.0470319 38.425C-0.0442073 39.2028 0.0653464 39.9902 0.365299 40.7127L15.2481 76.3617C15.5382 77.0726 16.0139 77.6927 16.6259 78.1579C17.2379 78.6231 17.964 78.9165 18.7292 79.0077L56.3569 83.2662C57.1231 83.3483 57.8982 83.2249 58.6019 82.9086C59.3056 82.5924 59.9123 82.0948 60.3595 81.4673L83.1048 50.077C83.5641 49.4405 83.8531 48.6982 83.9444 47.9204C84.0356 47.1426 83.926 46.3551 83.6261 45.6327L68.7433 9.9837C68.453 9.27292 67.9773 8.65295 67.3653 8.18788C66.7532 7.7228 66.0271 7.42951 65.262 7.33829Z"
                fill="#F2F9E1"
            />
            <ellipse
                cx="63.5412"
                cy="62.9167"
                rx="1.90909"
                ry="1.91666"
                fill="#DCF043"
            />
            <circle cx="33.6321" cy="88" r="2" fill="#ABD537" />
            <ellipse
                cx="20.2542"
                cy="23.6326"
                rx="2.62216"
                ry="2.63256"
                fill="#FB9435"
            />
            <ellipse
                rx="25.2956"
                ry="24.4374"
                transform="matrix(0.982775 0.184808 -0.184519 0.982829 27.0011 58.6926)"
                stroke="#B5B5B7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6 6 6 6 6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.1238 40.6452L15.413 45.8573C13.8993 46.531 13.2183 48.3042 13.892 49.8179L18.487 60.1423C19.1607 61.656 20.934 62.337 22.4477 61.6633L25.239 60.421L27.5253 65.5581C28.2052 67.0858 29.9948 67.773 31.5224 67.0931C33.0501 66.4132 33.7373 64.6237 33.0574 63.096L30.771 57.9588L32.7393 57.0828C33.1234 57.2126 33.5307 57.2851 33.9493 57.2925L54.7072 57.6573C57.6327 57.7087 59.6216 54.7046 58.4319 52.0315L47.0128 26.3743C45.8215 23.6975 42.2515 23.1676 40.334 25.3829L27.1238 40.6452Z"
                fill="#B5B5B7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.2178 28.5858C52.8273 28.1952 52.8273 27.5621 53.2178 27.1716L57.8036 22.5858C58.1942 22.1952 58.8273 22.1952 59.2178 22.5858L59.925 23.2929C60.3155 23.6834 60.3155 24.3166 59.925 24.7071L55.3392 29.2929C54.9486 29.6834 54.3155 29.6834 53.925 29.2929L53.2178 28.5858Z"
                fill="#60AC32"
                stroke="#60AC32"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.8641 32.7503C66.1193 33.2401 65.9291 33.844 65.4393 34.0992L59.1058 37.399C58.616 37.6542 58.012 37.464 57.7569 36.9742L57.2948 36.0874C57.0396 35.5976 57.2298 34.9936 57.7196 34.7385L64.0531 31.4387C64.5429 31.1835 65.1468 31.3737 65.402 31.8635L65.8641 32.7503Z"
                fill="#60AC32"
                stroke="#60AC32"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.4556 42.7161C60.4069 42.1659 60.8134 41.6805 61.3635 41.6318L67.3713 41.0999C67.9215 41.0512 68.4069 41.4577 68.4556 42.0078L68.5438 43.0039C68.5925 43.5541 68.186 44.0395 67.6359 44.0882L61.6281 44.6201C61.078 44.6688 60.5925 44.2623 60.5438 43.7122L60.4556 42.7161Z"
                fill="#60AC32"
                stroke="#60AC32"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <rect
                x="25.9965"
                y="54.606"
                width="4.05525"
                height="11.8406"
                rx="2.02763"
                transform="rotate(-23.9921 25.9965 54.606)"
                fill="white"
                stroke="#676F79"
                strokeWidth="2"
            />
            <path
                d="M28.5637 43.3547C28.0906 42.2918 28.2748 41.0512 29.0362 40.1715L42.6378 24.457C44.0759 22.7955 46.7534 23.193 47.6469 25.2005L59.066 50.8577C59.9583 52.8626 58.4666 55.1156 56.2724 55.0771L35.5145 54.7122C34.3488 54.6918 33.3005 53.9977 32.8264 52.9325L28.5637 43.3547Z"
                fill="white"
                stroke="#676F79"
                strokeWidth="2"
            />
            <path
                d="M16.3533 47.8309C15.9042 46.8218 16.3581 45.6396 17.3673 45.1905L28.7285 40.134L34.95 54.1128L23.5887 59.1692C22.5796 59.6184 21.3974 59.1644 20.9483 58.1553L16.3533 47.8309Z"
                fill="white"
                stroke="#676F79"
                strokeWidth="2"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="84" height="92" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
