import {RawEditorOptions} from 'tinymce';

import {showSnippetModal} from 'Interfaces/Snippets';
import {RendererParams} from 'Renderer/Renderer';

import {SISMergeFieldConfig, SisSnippetBtnConfig} from '../types';

import {addSisMergeFieldSyntax} from './addSisMergeFieldSyntax';
import {showAutoFillModal} from './autoFillModal';
import {generateToolbar, GenerateToolbarParams} from './generateToolbar';
import {preprocessPastedContent} from './preprocessPastedContent';
import {transformSisMergeFieldConfig} from './transformSisMergeFieldConfig';

type GenerateInitObjectParams = {
    enableFormat?: boolean;
    height?: number;
    mergeFieldBtnConfig?: SISMergeFieldConfig;
    snippetBtnConfig?: SisSnippetBtnConfig;
    rendererParams?: RendererParams;
    emptyText?: string;
    autofocus?: string | true;
    autoFillUrl?: string;
    autoFillParams?: object;
} & GenerateToolbarParams;

// disable camelcase warning because a lot of TinyMCE's config flags are in snake_case
/* eslint-disable camelcase */
export const generateInitObject = (
    props: GenerateInitObjectParams,
): RawEditorOptions & {
    selector?: undefined;
    target?: undefined;
} => {
    const {enableFormat, autoFillParams, autoFillUrl} = props;

    const result: RawEditorOptions & {
        selector?: undefined;
        target?: undefined;
    } = {
        height: props.height ?? 200,
        menubar: 'mergeFields',
        plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'print',
            'preview',
            'help',
            'wordcount',
            'pagebreak',
        ],
        toolbar: generateToolbar(props),
        setup: (editor) => {
            if (props.mergeFieldBtnConfig) {
                const mergeFields = transformSisMergeFieldConfig(
                    props.mergeFieldBtnConfig,
                );
                editor.ui.registry.addMenuButton('mergefieldbutton', {
                    text: 'Merge Fields',
                    fetch: (callback) => {
                        const items = mergeFields.map(({value, label}) => {
                            return {
                                type: 'menuitem',
                                text: label,
                                onAction: () =>
                                    editor.insertContent(
                                        addSisMergeFieldSyntax(value, label),
                                    ),
                            };
                        });
                        // Not sure why TS is complaining here, but this is following the example
                        // in TinyMCE's docs: https://www.tiny.cloud/docs/tinymce/6/custom-menu-toolbar-button/
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        callback(items);
                    },
                });
            }

            if (autoFillUrl) {
                editor.ui.registry.addButton('autoFillButton', {
                    text: 'Help me write this',
                    onAction: () => {
                        showAutoFillModal({
                            autoFillUrl,
                            autoFillParams,
                            insertCallback: (content) => {
                                editor.insertContent(content);
                            },
                            contentRequestUrl:
                                props.rendererParams?.contentRequestUrl,
                            refocusCallback: () => {
                                editor.focus();
                            },
                        });
                    },
                });
            }

            if (props.snippetBtnConfig) {
                editor.ui.registry.addButton('snippetbutton', {
                    text: 'Snippets...',
                    onAction: () => {
                        // checking again to make typescript happy,
                        // because it doesn't know that this will still
                        // be defined when onAction is called
                        if (props.snippetBtnConfig) {
                            showSnippetModal({
                                context: props.snippetBtnConfig.props.context,
                                replacement:
                                    props.snippetBtnConfig.props.replacement,
                                insertCallback: (content) => {
                                    editor.insertContent(content);
                                },
                                contentRequestUrl:
                                    props.rendererParams?.contentRequestUrl,
                                refocusCallback: () => {
                                    editor.focus();
                                },
                            });
                        }
                    },
                });
            }
        },
        content_css: '/dist/editorContent.css',
        branding: false,
        promotion: false,
        browser_spellcheck: true,
        contextmenu: 'image | table',
        font_family_formats: [
            'Andale Mono=andale mono,times',
            'Arial=arial,helvetica,sans-serif',
            'Arial Black=arial black,avant garde',
            'Book Antiqua=book antiqua,palatino',
            'Comic Sans MS=comic sans ms,sans-serif',
            'Courier New=courier new,courier',
            'Georgia=georgia,palatino',
            'Helvetica=helvetica',
            'Impact=impact,chicago',
            "PT Sans='PT Sans', 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif",
            'Symbol=symbol',
            'Tahoma=tahoma,arial,helvetica,sans-serif',
            'Terminal=terminal,monaco',
            'Times New Roman=times new roman,times',
            'Trebuchet MS=trebuchet ms,geneva',
            'Verdana=verdana,geneva',
            'Webdings=webdings',
            'Wingdings=wingdings,zapf dingbats',
        ].join(';'),
        formats: {
            bold: {inline: 'b'},
        },
        extended_valid_elements: 'b',
        placeholder: props.emptyText ?? '',
        auto_focus: props.autofocus,
        statusbar: false,
        skin: 'tinymce-5',
        highlight_on_focus: true,

        // by default, tiny creates a blob url, but that doesn't work on prod, probably
        // something to do with how it handles blob storage events, but further
        // investigation is needed. here we use base64 encoding instead
        images_upload_handler: (blobInfo) =>
            new Promise((resolve) => {
                resolve(
                    `data:${blobInfo.blob().type};base64,${blobInfo.base64()}`,
                );
            }),

        toolbar_mode: 'sliding',

        pagebreak_separator: '<div class="html-editor__page-break"></div>',

        // Make sure that URLs are always absolute
        relative_urls: false,
        document_base_url: window.origin,
        convert_urls: false,

        paste_preprocess: (editor, args) => {
            args.content = preprocessPastedContent(args.content);
        },
    };

    if (!enableFormat) {
        // disable markdown-style formatting via *asterisks*, _underscores_, etc
        result.text_patterns = false;
    }

    return result;
};
