import {useState} from 'react';

import {Button} from 'Components/button/base';
import {FormAction} from 'Components/button/formActionButton';
import CheckoutWrapper from 'Components/checkout/sca/CheckoutWrapper';
import {checkIfValid, getFields} from 'Interfaces/form/Form';

import {createFormattedAmount} from './createFormattedAmount';

type ModalData = {
    fields: Record<string, unknown>;
    url: string;
    amount: string;
};
type ScaCreditCardActionButtonProps = {currentAction: FormAction};

/**
 * This payment button is used in most payment flows, primarily in the parent portal
 * 2 important differences in this component compared to ScaCheckoutStripeButton:
 * - It gets payment information from form fields
 * - It handles form validation
 * - It does not set isEmail={true} in CheckoutWrapper
 * */
export const ScaCreditCardActionButton = ({
    currentAction,
}: ScaCreditCardActionButtonProps) => {
    const [modalData, setModalData] = useState<null | ModalData>(null);
    const handleClick = () => {
        const fields = getFields(currentAction.formActionName);
        const fieldMap: Record<string, unknown> = {};
        let isFormValid = true;
        fields.map(function (field) {
            const fieldValue = field.getValue();
            fieldMap[field.name] = fieldValue;
            if (
                !checkIfValid({
                    fields,
                    formActionName: currentAction.formActionName,
                    validators: currentAction.validators,
                    fieldName: field.name,
                    field,
                    value: fieldValue,
                })
            ) {
                isFormValid = false;
            }
        });

        if (isFormValid) {
            let amount: string | null = null;
            if (
                typeof fieldMap.amount === 'string' ||
                typeof fieldMap.amount === 'number'
            ) {
                amount = createFormattedAmount(fieldMap.amount);
            }
            if (
                typeof fieldMap.payment === 'string' ||
                typeof fieldMap.payment === 'number'
            ) {
                amount = createFormattedAmount(fieldMap.payment);
            }
            if (amount === null) {
                console.error('No amount!', fieldMap);
                return;
            }

            setModalData({
                fields: fieldMap,
                url: currentAction.actionUrl,
                amount,
            });
        }
    };
    const onModalClose = () => {
        setModalData(null);
    };
    return (
        <>
            <Button onClick={handleClick} text="Make Payment" color="green" />
            {modalData && (
                <CheckoutWrapper
                    fields={modalData.fields}
                    amount={modalData.amount}
                    url={modalData.url}
                    banAmex={true}
                    onModalClose={onModalClose}
                />
            )}
        </>
    );
};
