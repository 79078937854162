import {useState} from 'react';
import {connect} from 'react-redux';

import {Button} from 'Components/button/base';
import CheckoutWrapper from 'Components/checkout/sca/CheckoutWrapper';
import {
    selectSelectedIds,
    selectPrice,
    selectInsightBasketPrice,
} from 'State/selectors/Basket';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';

const WrappedButton = buttonWrapper(Button);

type ScaCheckoutStripeButtonProps = {
    pageUrl: string;
    disabled?: boolean;
    selectedIds: string[];
    price: {total: string | number};
    scaAuthUrl: string;
    scaRejectedAuthUrl: string;
    insightBasketPrice: string;
};

/**
 * This payment button is currently only used when buying SMS credits
 * 2 important differences in this component compared to ScaCreditCardActionButton:
 * - It gets some payment information from Redux
 * - It sets isEmail={true} in CheckoutWrapper
 * */
const ScaCheckoutStripeButton = (props: ScaCheckoutStripeButtonProps) => {
    const [modalData, setModalData] = useState<{
        amount: string;
        fields: Record<string, unknown>;
    } | null>(null);

    const onModalClose = () => {
        setModalData(null);
    };

    const handleClick = () => {
        const amount = props.insightBasketPrice
            ? props.insightBasketPrice
            : '£' + props.price.total;
        setModalData({
            amount,
            fields: {
                amount: amount,
                selectedIds: {value: props.selectedIds},
            },
        });
    };

    const {
        // don't want to pass it to Button
        pageUrl,
        ...rest
    } = props;

    return (
        <>
            <WrappedButton color="green" {...rest} onClick={handleClick} />
            {modalData && (
                <CheckoutWrapper
                    amount={modalData.amount}
                    fields={modalData.fields}
                    url={props.pageUrl}
                    banAmex={true}
                    onModalClose={onModalClose}
                    scaAuthUrl={props.scaAuthUrl}
                    scaRejectedAuthUrl={props.scaRejectedAuthUrl}
                    isEmail={true}
                />
            )}
        </>
    );
};

ScaCheckoutStripeButton.defaultProps = {
    disabled: false,
};

const mapStateToProps = (state) => {
    return {
        selectedIds: selectSelectedIds(state),
        price: selectPrice(state),
        insightBasketPrice: selectInsightBasketPrice(state),
    };
};

export default connect(mapStateToProps)(ScaCheckoutStripeButton);
