import './kpiPanelsEmptyState.scss';

const EmptyKpiPanelsIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="125"
        height="120"
        fill="none"
        viewBox="0 0 125 120"
    >
        <ellipse
            stroke="#B5B5B7"
            strokeDasharray="6 6 6 6 6 6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            rx="30.494"
            ry="29.232"
            transform="rotate(10.564 -232.231 214.638) skewX(-.156)"
        />
        <path
            fill="#F2F9E1"
            d="M94.164 17.147l-45.373-5.093c-.923-.098-1.858.05-2.706.428-.849.378-1.58.972-2.12 1.723L16.538 51.742c-.554.761-.902 1.649-1.012 2.58-.11.93.022 1.87.384 2.735l17.946 42.631c.35.85.923 1.592 1.661 2.148.738.557 1.614.907 2.536 1.017l45.373 5.092c.924.098 1.858-.049 2.707-.427.849-.379 1.58-.974 2.12-1.724l27.426-37.539c.554-.76.903-1.648 1.013-2.579.11-.93-.022-1.872-.384-2.735L98.362 20.31c-.35-.85-.924-1.591-1.662-2.147-.738-.557-1.613-.907-2.536-1.016z"
        />
        <path
            fill="#B5B5B7"
            d="M63.33 46C47.75 46 35.972 57.129 31.056 63.941c-.676.936-.676 2.182 0 3.118C35.972 73.87 47.749 85 63.33 85c15.436 0 29.29-10.103 35.245-16.793.886-.996.886-2.465.02-3.479C92.65 57.768 78.784 46 63.33 46z"
        />
        <path
            fill="#fff"
            stroke="#676F79"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="M64.364 45c-14.993 0-26.36 10.089-31.208 16.39-.735.955-.735 2.265 0 3.22C38.005 70.91 49.37 81 64.364 81c14.843 0 27.378-9.886 33.105-16.199.932-1.027.932-2.575 0-3.602C91.742 54.886 79.207 45 64.364 45z"
        />
        <path
            fill="#fff"
            stroke="#676F79"
            strokeWidth="2.5"
            d="M80.75 63c0 7.484-6.713 13.75-15.25 13.75-8.537 0-15.25-6.266-15.25-13.75s6.713-13.75 15.25-13.75c8.537 0 15.25 6.266 15.25 13.75z"
        />
        <ellipse cx="91.999" cy="83.5" fill="#DCF043" rx="2" ry="2.5" />
        <circle cx="74.5" cy="22.5" r="2.5" fill="#ABD537" />
        <circle cx="40" cy="37" r="3" fill="#FB9435" />
        <ellipse cx="66" cy="63" fill="#C4C4C4" rx="7" ry="6" />
        <path
            fill="#60AC32"
            fillRule="evenodd"
            d="M90.679 38.434c.808.753.847 2.013.087 2.815L46.142 88.343c-.76.801-2.03.841-2.839.088l-1.467-1.368c-.808-.753-.847-2.013-.087-2.815l44.624-47.094c.76-.801 2.03-.84 2.839-.088l1.467 1.368z"
            clipRule="evenodd"
        />
    </svg>
);

export const KpiPanelsEmptyState = () => (
    <div className="kpi-panels-empty-state">
        <EmptyKpiPanelsIcon />
        <div className="kpi-panels-empty-state-text-container">
            <h3 className="kpi-panels-empty-text-heading">
                You’ve hidden all your headline data
            </h3>
            <p>
                Click on “Select views” to un-hide your attendance, behaviour or
                attainment data.{' '}
            </p>
        </div>
    </div>
);
