import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';

import {Button} from 'Components/button/base';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {LoadingSpinner} from 'Components/loadingSpinner';
import {RendererParams} from 'Renderer/Renderer';
import {useComponentDidMount} from 'Root/core/utils/useComponentDidMount';
import {useIsMounted} from 'Root/core/utils/useIsMounted';
import helper from 'Utils/helper';
import {parseUnsafeInt} from 'Utils/parseUnsafeInt';
import {removeAllTags, sanitizeChatPanelMessage} from 'Utils/sanitizeHtml';

import {getHistoricMessages} from './getHistoricMessages';
import {getNewAgentMessage} from './getNewAgentMessage';
import {RatingThumbs} from './RatingThumbs';

import './chatPanel.scss';

export type Message = {
    text: string;
    type: 'user' | 'agent';
    id: string;
};

type ChatPanelProps = {
    title?: string;
    ratingUrl?: string;
    chatUrl: string;
    newChatUrl?: string;
    logUrl?: string;
    historicChatUrl?: string;
    lockConversation?: boolean;
    messageLimit?: string | number;
    chatId?: string;
    placeholder?: string;
    rendererParams?: RendererParams;
};

export const ChatPanel = ({
    title,
    ratingUrl,
    chatUrl,
    logUrl,
    newChatUrl,
    lockConversation = false,
    messageLimit: unsafeMessageLimit,
    placeholder = 'Ask a question...',
    chatId: chatIdFromProps,
    historicChatUrl,
    rendererParams,
}: ChatPanelProps) => {
    const _isMounted = useIsMounted();
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const [inputValue, setInputValue] = useState('');
    const [chatId, setChatId] = useState<undefined | string>(chatIdFromProps);
    const [messages, setMessages] = useState<Message[]>([]);
    const addMessage = (message: Message) =>
        setMessages((prevMessages) => [...prevMessages, message]);
    const [isPending, setIsPending] = useState(false);
    const [isHistoricDataLoading, setIsHistoricalDataLoading] = useState(false);
    const [hasHistoricalDataLoaded, setHasHistoricalDataLoaded] =
        useState(false);

    const messageLimit = parseUnsafeInt(unsafeMessageLimit, 0);
    const hasReachedMessageLimit =
        messageLimit > 0 && messages.length >= messageLimit;

    useComponentDidMount(() => {
        if (rendererParams?.type === 'slideover') {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    });
    useEffect(() => {
        if (!_isMounted) {
            return;
        }
        if (!historicChatUrl) {
            return;
        }
        if (hasHistoricalDataLoaded) {
            return;
        }
        const fetchHistoricMessages = async () => {
            setIsHistoricalDataLoading(true);
            const historicMessages = await getHistoricMessages(
                `${historicChatUrl}/${chatIdFromProps}`,
            );

            if (!_isMounted) {
                return;
            }
            setMessages((prevMessages) => [
                ...prevMessages,
                ...historicMessages,
            ]);
            setIsHistoricalDataLoading(false);
            setHasHistoricalDataLoaded(true);
        };
        fetchHistoricMessages();
    }, [chatIdFromProps, historicChatUrl, _isMounted, hasHistoricalDataLoaded]);
    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTo({
                top: listRef.current.scrollHeight,
                behavior: 'smooth',
            });
            listRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [messages]);

    const fetchNewAgentMessage = async (userMessage: string) => {
        setIsPending(true);
        const message = await getNewAgentMessage({
            chatId,
            chatUrl,
            userMessage,
        });
        if (message) {
            addMessage({
                text: sanitizeChatPanelMessage(message.text),
                type: 'agent',
                id: message.id,
            });
            if (message.chatId) {
                setChatId(message.chatId);
            }
            inputRef.current?.focus();
        }
        setIsPending(false);
    };

    const submitNewMessage = () => {
        const sanitized = removeAllTags(inputValue);
        addMessage({type: 'user', text: sanitized, id: helper.getUid()});
        fetchNewAgentMessage(sanitized);
        setInputValue('');
    };
    const canSubmit =
        !isHistoricDataLoading && !isPending && inputValue.length > 0;
    return (
        <section aria-label={`Chat Panel: ${title}`} className="chat-panel">
            {title && (
                <section className="chat-panel__header">
                    <h2>{title}</h2>
                </section>
            )}
            <div className="chat-panel__message-list-wrapper">
                <ul className="chat-panel-message-list" ref={listRef}>
                    {!isHistoricDataLoading && messages.length === 0 && (
                        <span>
                            {lockConversation
                                ? 'This conversation is locked but has no messages'
                                : placeholder}
                        </span>
                    )}
                    {messages.map((message, index) => (
                        <li
                            className={classNames('chat-panel-message', {
                                'chat-panel-message--user':
                                    message.type === 'user',
                                'chat-panel-message--agent':
                                    message.type === 'agent',
                            })}
                            key={`${message.id}_${index}`}
                        >
                            <span
                                dangerouslySetInnerHTML={{__html: message.text}}
                            ></span>
                            {ratingUrl &&
                                chatId &&
                                message.type === 'agent' && (
                                    <RatingThumbs
                                        message={message}
                                        ratingUrl={ratingUrl}
                                        chatId={chatId}
                                    />
                                )}
                        </li>
                    ))}
                    {(isPending || isHistoricDataLoading) && (
                        <li
                            aria-label="Loading response"
                            className="chat-panel-loading-container"
                        >
                            <LoadingSpinner className="chat-panel__loading-spinner" />
                        </li>
                    )}
                    {messageLimit > 0 && messages.length > 0 && (
                        <div className="chat-panel__message-limit">
                            {messages.length} / {messageLimit}
                        </div>
                    )}
                </ul>
            </div>
            {!lockConversation && !hasReachedMessageLimit && (
                <div className="chat-panel-footer">
                    <textarea
                        className="chat-panel__input"
                        ref={inputRef}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                        aria-label="Chat panel input"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                // Submit on enter, but still allow shift enter to make newlines
                                e.preventDefault();
                                if (canSubmit) {
                                    submitNewMessage();
                                }
                            }
                        }}
                    />
                    <Button
                        disabled={!canSubmit}
                        onClick={submitNewMessage}
                        askArborLogo
                        color="green"
                        ariaLabel="submit message"
                        tooltipHTML="You can submit the message using the shortcut <b>enter</b>, and create new lines with <b>shift enter</b>."
                    ></Button>
                </div>
            )}
            {hasReachedMessageLimit && (
                <div className="chat-panel-footer">
                    You've reached the maximum number of messages for this
                    conversation
                </div>
            )}
            {((chatId && logUrl) || newChatUrl) && (
                <div className="chat-panel-footer">
                    {chatId && logUrl && (
                        <KeyboardFocusableLink
                            url={`${logUrl}/chat-id/${chatId}`}
                        >
                            See message log
                        </KeyboardFocusableLink>
                    )}
                    {newChatUrl && (
                        <KeyboardFocusableLink
                            linkWrapperClassName={classNames({
                                'chat-panel-link--active':
                                    hasReachedMessageLimit,
                            })}
                            url={newChatUrl}
                        >
                            New chat
                        </KeyboardFocusableLink>
                    )}
                </div>
            )}
        </section>
    );
};
