export const ClipboardIcon = () => (
    <svg
        width="84"
        height="92"
        viewBox="0 0 84 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M64.9482 15.2965L27.3205 11.0378C26.5543 10.9558 25.7793 11.0793 25.0757 11.3955C24.3721 11.7117 23.7653 12.2091 23.3181 12.8363L0.572794 44.2266C0.113432 44.8631 -0.175572 45.6054 -0.266811 46.3832C-0.35805 47.161 -0.248496 47.9485 0.0514565 48.6709L14.9342 84.3199C15.2244 85.0308 15.7 85.6509 16.312 86.1161C16.9241 86.5813 17.6502 86.8747 18.4154 86.966L56.043 91.2245C56.8093 91.3066 57.5843 91.1831 58.288 90.8669C58.9917 90.5506 59.5985 90.053 60.0456 89.4256L82.7909 58.0353C83.2503 57.3988 83.5393 56.6564 83.6305 55.8786C83.7218 55.1009 83.6122 54.3134 83.3122 53.591L68.4295 17.9419C68.1392 17.2312 67.6635 16.6112 67.0514 16.1461C66.4394 15.6811 65.7133 15.3878 64.9482 15.2965Z"
                fill="#F2F9E1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.3347 23.7708C49.3347 20.5837 46.7122 18 43.4773 18C40.2423 18 37.6198 20.5837 37.6198 23.7708L32.8099 23.7708C31.153 23.7708 29.8099 25.114 29.8099 26.7708V27.6181H26C23.7909 27.6181 22 29.4089 22 31.618V71.7083C22 73.9175 23.7909 75.7083 26 75.7083H60.9545C63.1637 75.7083 64.9545 73.9175 64.9545 71.7083V31.6181C64.9545 29.4089 63.1637 27.6181 60.9545 27.6181H57.1446V25.7708C57.1446 24.6663 56.2492 23.7708 55.1446 23.7708L49.3347 23.7708ZM47.6147 24.8723C47.6147 25.9808 46.6989 26.8795 45.5693 26.8795C44.4396 26.8795 43.5238 25.9808 43.5238 24.8723C43.5238 23.7637 44.4396 22.865 45.5693 22.865C46.6989 22.865 47.6147 23.7637 47.6147 24.8723Z"
                fill="#B5B5B7"
            />
            <ellipse
                cx="13.8825"
                cy="45.6221"
                rx="2.62216"
                ry="2.63256"
                fill="#FB9435"
            />
            <ellipse
                cx="21"
                cy="80.5"
                rx="1.90909"
                ry="1.91666"
                fill="#DCF043"
            />
            <ellipse
                cx="62.5228"
                cy="3.35419"
                rx="1.43181"
                ry="1.4375"
                fill="#77BC48"
            />
            <ellipse
                cx="76.841"
                cy="56.0625"
                rx="2.38636"
                ry="2.39583"
                fill="#ABD537"
            />
            <ellipse
                rx="25.2956"
                ry="24.4374"
                transform="matrix(0.999304 0.0372935 -0.0369997 0.999315 51.5867 30.7701)"
                stroke="#B5B5B7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6 6 6 6 6"
            />
            <rect
                x="25.8182"
                y="25.9167"
                width="40"
                height="45.9167"
                rx="3"
                fill="white"
                stroke="#676F79"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.1819 20H51.5455C51.5455 16.6863 48.9813 14 45.8182 14C42.6551 14 40.091 16.6863 40.091 20H35.4546C33.7977 20 32.4546 21.3431 32.4546 23V28C32.4546 29.1046 33.35 30 34.4546 30H57.1819C58.2864 30 59.1819 29.1046 59.1819 28V22C59.1819 20.8954 58.2864 20 57.1819 20ZM46.0001 22C47.1046 22 48.0001 21.1046 48.0001 20C48.0001 18.8954 47.1046 18 46.0001 18C44.8955 18 44.0001 18.8954 44.0001 20C44.0001 21.1046 44.8955 22 46.0001 22Z"
                fill="white"
            />
            <path
                d="M51.5455 20H50.5455V21H51.5455V20ZM40.091 20V21H41.091V20H40.091ZM51.5455 21H57.1819V19H51.5455V21ZM45.8182 15C48.3857 15 50.5455 17.1942 50.5455 20H52.5455C52.5455 16.1784 49.5769 13 45.8182 13V15ZM41.091 20C41.091 17.1942 43.2508 15 45.8182 15V13C42.0595 13 39.091 16.1784 39.091 20H41.091ZM35.4546 21H40.091V19H35.4546V21ZM33.4546 23C33.4546 21.8954 34.35 21 35.4546 21V19C33.2455 19 31.4546 20.7909 31.4546 23H33.4546ZM33.4546 28V23H31.4546V28H33.4546ZM34.4546 29C33.9023 29 33.4546 28.5523 33.4546 28H31.4546C31.4546 29.6569 32.7977 31 34.4546 31V29ZM57.1819 29H34.4546V31H57.1819V29ZM58.1819 28C58.1819 28.5523 57.7341 29 57.1819 29V31C58.8387 31 60.1819 29.6569 60.1819 28H58.1819ZM58.1819 22V28H60.1819V22H58.1819ZM57.1819 21C57.7341 21 58.1819 21.4477 58.1819 22H60.1819C60.1819 20.3431 58.8387 19 57.1819 19V21ZM47.0001 20C47.0001 20.5523 46.5523 21 46.0001 21V23C47.6569 23 49.0001 21.6569 49.0001 20H47.0001ZM46.0001 19C46.5523 19 47.0001 19.4477 47.0001 20H49.0001C49.0001 18.3431 47.6569 17 46.0001 17V19ZM45.0001 20C45.0001 19.4477 45.4478 19 46.0001 19V17C44.3432 17 43.0001 18.3431 43.0001 20H45.0001ZM46.0001 21C45.4478 21 45.0001 20.5523 45.0001 20H43.0001C43.0001 21.6569 44.3432 23 46.0001 23V21Z"
                fill="#676F79"
            />
            <path
                d="M43.8146 56.1816C43.3359 56.6617 42.5883 56.6617 42.1096 56.1816L35.6772 49.7322C35.1986 49.2521 35.1986 48.5022 35.6772 48.0221L37.323 46.3714C37.8016 45.8913 38.5492 45.8913 39.0279 46.3714L42.9609 50.3163L52.6378 40.6101C53.1165 40.13 53.8641 40.13 54.3427 40.6101L55.9885 42.2608C56.4671 42.7409 56.4671 43.4908 55.9885 43.9709L43.8146 56.1816Z"
                fill="#60AC32"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="84" height="92" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
