import classNames from 'classnames';
import {Component} from 'react';

import {Button} from 'Components/button/base';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import externalConfig from 'Config/External';
import Text from 'Config/Text';
import {APP_CONTAINER_ID} from 'Constants/idStrings';
import {raiseError} from 'Interfaces/error/Error';
import reportToSisSentry from 'Interfaces/raven/Raven';
import {successLoginHandler} from 'Interfaces/Session';
import {IEBanner} from 'Renderer/IEBannerData';
import url from 'Services/url/Url';
import {detectInternetExplorer} from 'Utils/browserDetection';

import {AzureButton, GoogleButton} from './buttons';
import {FirstTimeLoginModals, ResetPasswordModals} from './emailFormModals';
import {FormContainer, TextField, validationRules} from './form';
import {MessageContainer} from './messageContainer';
import {sendLoginRequest} from './sendLoginRequest';

import './login.scss';

export class Login extends Component {
    constructor() {
        super();
        this.state = {
            errorMessage: '',
            isSubmitting: false,
            isFirstTimeLoginModalOpen: false,
            isResetPasswordModalOpen: false,
            isBrowserIE: detectInternetExplorer(),
        };
    }

    handleValidSubmit = async (formData) => {
        this.setState({isSubmitting: true});

        try {
            const response = await sendLoginRequest(
                formData.username,
                formData.password,
            );
            successLoginHandler(response);
        } catch (error) {
            // User displayable errors from backend will be inside error.items[0]
            const data = error.items && error.items[0];
            if (data) {
                this.setState({
                    errorMessage: data.message ? data.message : data,
                });
            } else {
                // Unexpected error format, so show a generic error modal and log to Sentry
                raiseError(
                    'Sorry! We had a problem logging you in.',
                    Text.ERROR.GENERIC_MESSAGE,
                    'Login Error:',
                );
                reportToSisSentry(error, {
                    message: error.message,
                });
            }
        } finally {
            this.setState({isSubmitting: false});
        }
    };

    // Login form is rendered to the app container. We need to remove the 'app-container' class while
    // this form is showing
    componentDidMount = () => {
        const appContainer = document.getElementById(APP_CONTAINER_ID);
        if (
            appContainer &&
            typeof appContainer.removeAttribute === 'function'
        ) {
            appContainer.removeAttribute('class', 'app-container');
        }

        if (window.location.href.includes(url.AUTH.EXPIRED_RESET_PASSWORD)) {
            this.setState({
                errorMessage:
                    'You visited an expired reset password link. If you don\'t remember your password, please use "Forgot your password?" below',
            });
        }
    };

    componentWillUnmount = () => {
        const appContainer = document.getElementById(APP_CONTAINER_ID);
        if (appContainer && typeof appContainer.setAttribute === 'function') {
            appContainer.setAttribute('class', 'app-container');
        }
    };

    render() {
        const config = externalConfig.getConfig();
        const shouldRenderAzureSso = config.azureSso;
        const shouldRenderGoogleSso = config.sso;
        const shouldRenderLoginSeparator =
            shouldRenderAzureSso || shouldRenderGoogleSso;

        const {
            isSubmitting,
            isFirstTimeLoginModalOpen,
            isResetPasswordModalOpen,
            isBrowserIE,
            errorMessage,
        } = this.state;
        return (
            <div className="login-overflow-wrapper" role="presentation">
                <div className="login-content" role="presentation">
                    <div
                        className={classNames('login-school-logo', {
                            'login-school-logo-with-error-message':
                                errorMessage,
                        })}
                    >
                        <img
                            alt={`${config.schoolInfo.schoolName} logo`}
                            src={config.schoolInfo.schoolLogo || url.LOGO}
                        />
                        <div>{config.schoolInfo.schoolName}</div>
                    </div>
                    {isBrowserIE && (
                        <div className="IE-banner-wrapper">
                            <IEBanner />
                        </div>
                    )}
                    <MessageContainer caption={errorMessage} />
                    <div
                        className={classNames('login-main-content', {
                            'login-main-content--error-message': errorMessage,
                        })}
                    >
                        <h1 className="login-title">Log in</h1>
                        {shouldRenderAzureSso && <AzureButton />}
                        {shouldRenderGoogleSso && <GoogleButton />}
                        {shouldRenderLoginSeparator && (
                            <div className="login-separator">Or</div>
                        )}
                        <FormContainer
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validationSchema={{
                                username: validationRules.required,
                                password: validationRules.required,
                            }}
                            aria-label="Login form"
                            onValidSubmit={this.handleValidSubmit}
                            renderContents={({
                                hasAttemptedFormSubmit,
                                formState,
                                formErrors,
                                onFormFieldChange,
                            }) => (
                                <div role="presentation">
                                    <div
                                        role="presentation"
                                        className="login-fields-container"
                                    >
                                        <TextField
                                            id="username"
                                            value={formState.username}
                                            onChange={onFormFieldChange(
                                                'username',
                                            )}
                                            autoFocus
                                            placeholder="Enter your email address"
                                            className="login-input-field"
                                            errorText={formErrors.username}
                                            showErrorText={
                                                hasAttemptedFormSubmit
                                            }
                                            aria-label="Username"
                                        />
                                        <TextField
                                            id="password"
                                            value={formState.password}
                                            onChange={onFormFieldChange(
                                                'password',
                                            )}
                                            type="password"
                                            placeholder="Enter your password"
                                            className="login-input-field"
                                            errorText={formErrors.password}
                                            showErrorText={
                                                hasAttemptedFormSubmit
                                            }
                                            aria-label="Password"
                                        />
                                    </div>
                                    <div className="login-links-container">
                                        <KeyboardFocusableButton
                                            className="login-links"
                                            borderRadius="4px"
                                            onClick={() =>
                                                this.setState({
                                                    isFirstTimeLoginModalOpen: true,
                                                })
                                            }
                                            type="button"
                                        >
                                            First time logging in?
                                        </KeyboardFocusableButton>
                                        <KeyboardFocusableButton
                                            className="login-links"
                                            borderRadius="4px"
                                            onClick={() =>
                                                this.setState({
                                                    isResetPasswordModalOpen: true,
                                                })
                                            }
                                            type="button"
                                        >
                                            Forgot your password?
                                        </KeyboardFocusableButton>
                                    </div>
                                    <div className="login-submit-button-wrapper">
                                        <Button
                                            text={
                                                isSubmitting
                                                    ? 'Logging in'
                                                    : 'Log in'
                                            }
                                            color="green"
                                            role="submit"
                                            className="login-submit-button"
                                            disabled={isSubmitting}
                                        />
                                        {isSubmitting && (
                                            <div className="dot-flashing" />
                                        )}
                                    </div>
                                </div>
                            )}
                        />
                        <div className="login-help">
                            Trouble logging in? Click{' '}
                            <KeyboardFocusableLink
                                url={url.ARBOR.LOGIN_SUPPORT}
                                target="_blank"
                                className="login-links"
                                aria-label="Get help logging in"
                            >
                                here
                            </KeyboardFocusableLink>{' '}
                            for help
                        </div>
                    </div>

                    <footer className="login-footer" aria-label="Login footer">
                        <KeyboardFocusableLink
                            url={url.ARBOR.WEB_SITE}
                            target="_blank"
                            className="login-arbor-logo"
                        >
                            <span>Powered by</span>
                            <img
                                src={url.ARBOR_LOGO_SVG}
                                alt="Arbor Education"
                            />
                        </KeyboardFocusableLink>
                    </footer>

                    {isFirstTimeLoginModalOpen && (
                        <FirstTimeLoginModals
                            onClose={() =>
                                this.setState({
                                    isFirstTimeLoginModalOpen: false,
                                })
                            }
                        />
                    )}
                    {isResetPasswordModalOpen && (
                        <ResetPasswordModals
                            onClose={() =>
                                this.setState({isResetPasswordModalOpen: false})
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}
