export const NotificationIcon = () => (
    <svg
        width="84"
        height="92"
        viewBox="0 0 84 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M65.3913 8.25491L27.7636 3.99614C26.9974 3.91417 26.2224 4.03766 25.5188 4.35385C24.8152 4.67003 24.2084 5.16744 23.7612 5.79468L1.01591 37.185C0.556547 37.8215 0.267544 38.5638 0.176304 39.3416C0.0850652 40.1194 0.194619 40.9069 0.494572 41.6293L15.3774 77.2783C15.6675 77.9892 16.1432 78.6093 16.7552 79.0745C17.3672 79.5397 18.0933 79.8331 18.8585 79.9243L56.4861 84.1828C57.2524 84.265 58.0274 84.1415 58.7311 83.8253C59.4348 83.509 60.0416 83.0114 60.4887 82.3839L83.234 50.9936C83.6934 50.3572 83.9824 49.6148 84.0736 48.837C84.1649 48.0592 84.0553 47.2718 83.7554 46.5493L68.8726 10.9003C68.5823 10.1895 68.1066 9.56958 67.4945 9.1045C66.8825 8.63943 66.1564 8.34613 65.3913 8.25491Z"
                fill="#F2F9E1"
            />
            <ellipse
                cx="13.0492"
                cy="56.2211"
                rx="1.64738"
                ry="1.65391"
                fill="#FB9435"
            />
            <ellipse
                cx="58.7982"
                cy="22.2338"
                rx="2.38636"
                ry="2.39583"
                fill="#ABD537"
            />
            <ellipse
                rx="22.9408"
                ry="22.1625"
                transform="matrix(0.988471 -0.151408 0.151699 0.988427 28.6003 59.8323)"
                stroke="#B5B5B7"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6 6 6 6 6"
            />
            <ellipse
                rx="6.32077"
                ry="6.52566"
                transform="matrix(0.92419 -0.381932 0.364843 0.931069 44.046 60.7523)"
                fill="#B5B5B7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.6635 15.6315C30.6519 15.5999 30.6399 15.5683 30.6275 15.5367C29.6885 13.1398 26.8723 12.0461 24.3375 13.0938C21.8028 14.1415 20.5092 16.9337 21.448 19.3305L21.4469 19.331L23.8277 25.4068L33.0074 21.6132L30.6635 15.6315Z"
                fill="#B5B5B7"
            />
            <path
                d="M19.2877 27.6686C20.9003 24.5565 21.9157 23.58 23.8304 22.2801L25.8231 21.1789L28.7699 19.961L30.9498 19.3379C33.2076 18.9134 34.5986 18.8954 37.8607 19.993C41.5696 21.2408 42.8242 22.9744 44.1925 24.8652C45.5608 26.7559 46.4761 28.4084 47.0281 29.7528C47.4697 30.8283 49.1779 35.3728 49.9768 37.5106C50.5587 38.8316 51.9414 41.7762 52.8171 42.9862L52.8171 42.9863C53.9118 44.4989 55.7812 47.082 58.0267 48.6341C59.48 49.6386 61.5625 50.8059 62.7406 51.4645C63.7918 52.0521 64.1566 53.0722 63.0089 53.9677L45.5216 61.1946L24.0646 70.0619C22.6329 70.2324 21.0974 68.4993 21.4592 67.3277C21.8647 66.0147 22.5877 62.8744 22.8919 61.1029C23.5448 57.3003 23.3608 56.8377 23.3538 55.0215C23.3468 53.2053 23.0631 50.6256 22.3642 48.9219C21.4984 46.8117 19.6653 42.3188 19.2584 41.229C18.7498 39.8667 18.2954 38.0237 18.007 35.6867C17.7187 33.3496 17.4543 31.2068 19.2877 27.6686Z"
                fill="#B5B5B7"
            />
            <circle
                cx="45.8451"
                cy="58.5076"
                r="4.86481"
                transform="rotate(-21.9172 45.8451 58.5076)"
                fill="white"
                stroke="#676F79"
                strokeWidth="2"
            />
            <mask id="path-9-inside-1" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.6113 19.2005C24.6097 19.1966 24.6081 19.1926 24.6065 19.1887C23.7429 17.0423 24.9568 14.5322 27.3179 13.5823C29.6789 12.6323 32.2929 13.6022 33.1565 15.7486L33.1565 15.7486L35.3457 21.1895L26.7957 24.6296L24.6113 19.2005Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.6113 19.2005C24.6097 19.1966 24.6081 19.1926 24.6065 19.1887C23.7429 17.0423 24.9568 14.5322 27.3179 13.5823C29.6789 12.6323 32.2929 13.6022 33.1565 15.7486L33.1565 15.7486L35.3457 21.1895L26.7957 24.6296L24.6113 19.2005Z"
                fill="white"
            />
            <path
                d="M24.6113 19.2005L26.4667 18.4539L26.4643 18.4479L24.6113 19.2005ZM33.1565 15.7486L31.3011 16.4951L32.0476 18.3505L33.9031 17.604L33.1565 15.7486ZM33.1565 15.7486L35.012 15.002L34.2655 13.1466L32.41 13.8931L33.1565 15.7486ZM35.3457 21.1895L36.0922 23.0449L37.9477 22.2984L37.2011 20.443L35.3457 21.1895ZM26.7957 24.6296L24.9402 25.3761L25.6867 27.2315L27.5422 26.485L26.7957 24.6296ZM26.4643 18.4479C26.4635 18.446 26.4627 18.4441 26.462 18.4421L22.7511 19.9352C22.7535 19.9412 22.7559 19.9471 22.7583 19.9531L26.4643 18.4479ZM26.462 18.4421C26.076 17.483 26.5577 16.0439 28.0644 15.4377L26.5713 11.7268C23.3559 13.0205 21.4098 16.6016 22.7511 19.9352L26.462 18.4421ZM28.0644 15.4377C29.571 14.8315 30.9151 15.5359 31.3011 16.4951L35.012 15.002C33.6707 11.6685 29.7867 10.4331 26.5713 11.7268L28.0644 15.4377ZM33.9031 17.604L33.9031 17.604L32.41 13.8931L32.41 13.8931L33.9031 17.604ZM31.3011 16.4951L33.4902 21.936L37.2011 20.443L35.012 15.002L31.3011 16.4951ZM34.5992 19.334L26.0491 22.7741L27.5422 26.485L36.0922 23.0449L34.5992 19.334ZM28.6511 23.883L26.4667 18.4539L22.7558 19.947L24.9402 25.3761L28.6511 23.883Z"
                fill="#676F79"
                mask="url(#path-9-inside-1)"
            />
            <path
                d="M23.055 27.692C24.5733 24.9098 25.5227 24.0333 27.3095 22.8628L29.1673 21.8688L31.912 20.7644L33.9406 20.1947C36.0401 19.8015 37.332 19.7762 40.3541 20.7317C43.7901 21.818 44.9437 23.3535 46.2018 25.0281C47.46 26.7027 48.299 28.1683 48.8027 29.3617C49.2056 30.3165 50.7617 34.3522 51.4894 36.2507C52.021 37.4232 53.2855 40.0362 54.0907 41.1079C55.0972 42.4476 56.8161 44.7355 58.8911 46.1026C60.234 46.9874 62.1602 48.013 63.2499 48.5916C64.2222 49.1079 64.5542 50.0138 63.4824 50.819L47.1945 57.3725L27.2093 65.4135C25.8786 65.5749 25.4906 64.6915 25.8344 63.6457C26.2197 62.4736 26.8989 60.3998 27.2548 58.8315C27.8048 56.4082 27.4601 53.5674 27.2583 51.9039C27.0969 50.5732 26.1993 47.8126 25.7707 46.5986C24.9808 44.7252 23.3083 40.7363 22.9377 39.7685C22.4744 38.5588 22.0647 36.9205 21.8124 34.8412C21.5601 32.7619 21.3288 30.8553 23.055 27.692Z"
                fill="white"
                stroke="#676F79"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.2479 34.0411C28.3353 34.7978 28.5191 35.3809 28.6805 35.6718L28.9232 36.1089C29.1913 36.5918 29.0171 37.2005 28.5343 37.4686L27.66 37.954C27.1771 38.222 26.5683 38.0479 26.3003 37.565L26.0576 37.1278C25.6354 36.3673 25.3801 35.3578 25.2677 34.3855C25.1533 33.3955 25.1683 32.2829 25.4035 31.2763C25.6721 30.1264 26.0462 29.188 26.6928 28.3412C27.3197 27.5203 28.1321 26.8812 29.0942 26.2214L29.5066 25.9386C29.9621 25.6263 30.5845 25.7423 30.8968 26.1978L31.4624 27.0225C31.7747 27.478 31.6587 28.1005 31.2032 28.4128L30.7909 28.6956C29.8945 29.3102 29.4058 29.7315 29.0772 30.1619C28.7682 30.5665 28.5324 31.0704 28.3248 31.9588C28.1868 32.5493 28.1625 33.3021 28.2479 34.0411Z"
                fill="#60AC32"
                stroke="#60AC32"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <ellipse
                cx="63.0937"
                cy="68.8042"
                rx="1.90909"
                ry="1.91666"
                fill="#DCF043"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="84" height="92" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
