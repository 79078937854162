import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    getStudentAlertsRequest,
    dismissStudentAlertRequest,
} from 'BackendRenderedComponents/dashboard/redux/actions';
import {selectStudentAlerts} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {ALERT_TYPE_ENUM} from 'BackendRenderedComponents/dashboard/redux/types';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import url from 'Services/url/Url';

import {NotificationPanel, NotificationEmptyState} from '../NotificationPanel';

import {NotificationIcon} from './NotificationIcon';

export class UnconnectedStudentAlerts extends Component {
    componentDidMount() {
        this.props.actions.getStudentAlertsRequest();
    }

    handleDismiss = (id) => this.props.actions.dismissStudentAlertRequest(id);

    render() {
        const {
            studentAlerts: {total, items, isLoading},
            redirectUrl,
        } = this.props;

        return (
            <NotificationPanel
                title="Alerts"
                total={total}
                items={items}
                isLoading={isLoading}
                onDismiss={this.handleDismiss}
                redirectUrl={redirectUrl}
                emptyState={
                    <NotificationEmptyState
                        title={'All clear!'}
                        description={
                            <span>
                                There are no alerts for you right now. Find out
                                how alerts work{' '}
                                <KeyboardFocusableLink
                                    url={url.ARBOR.MY_HOMEPAGE_SUPPORT}
                                    target="_blank"
                                >
                                    here
                                </KeyboardFocusableLink>
                                .
                            </span>
                        }
                        icon={<NotificationIcon />}
                    />
                }
            />
        );
    }
}

UnconnectedStudentAlerts.propTypes = {
    studentAlerts: PropTypes.exact({
        isLoading: PropTypes.bool,
        total: PropTypes.number,
        items: PropTypes.arrayOf(
            PropTypes.exact({
                id: PropTypes.string,
                title: PropTypes.string,
                createdAt: PropTypes.string,
                type: PropTypes.oneOf([
                    ALERT_TYPE_ENUM.PINNED_NOTE,
                    ALERT_TYPE_ENUM.BIRTHDAY,
                    ALERT_TYPE_ENUM.DETENTION,
                    ALERT_TYPE_ENUM.SAFEGUARDING_NOTE,
                    ALERT_TYPE_ENUM.CUSTOM_GROUP_JOINED,
                    ALERT_TYPE_ENUM.CUSTOM_GROUP_LEFT,
                ]),
            }),
        ),
    }),
    redirectUrl: PropTypes.string,
    actions: PropTypes.exact({
        getStudentAlertsRequest: PropTypes.func,
        dismissStudentAlertRequest: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    studentAlerts: selectStudentAlerts(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getStudentAlertsRequest,
            dismissStudentAlertRequest,
        },
        dispatch,
    ),
});

export const StudentAlerts = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedStudentAlerts);
