import {httpPost} from 'Interfaces/httpClient';

type AgentMessageResponse = {
    items: [
        {
            text: string;
            id: string;
            chatId?: string;
        },
    ];
};
export const getNewAgentMessage = async ({chatId, chatUrl, userMessage}) => {
    try {
        const response: AgentMessageResponse = await httpPost(chatUrl, {
            chatId,
            userMessage,
        });
        if (!response || !response.items) {
            console.error('Invalid response in getNewAgentMessage', {
                chatUrl,
                chatId,
                userMessage,
                response,
            });
            return null;
        }
        return response.items[0] ?? null;
    } catch (e) {
        console.error('Error in getNewAgentMessage', e);
        return null;
    }
};
